import React from "react";
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa'

const Footer = () => {
  return (
    <>
      <div class="main-footer">
        <div className="footer-top">
          <div className="footerlogo-img">
            <img src="./Footerwis.jpg" alt="" />
          </div>
          <div className="footer-content">
            <p>Have any questions?</p>
            <p>
              Call Us: +91 7554201512  ,+91 97132 14087 , Mail Us:
              nwisdom4school@gmail.com
            </p>
            <p>
              Address -  Rajeev Nagar A-Sector, Ayodhya by Pass Road, Bhopal, Madhya Pradesh
            </p>
          </div>
          <div className="socialmedia-link">
            <div className="socialall">
              <a
                href="https://www.facebook.com/newwisdomschool/"
                target="_blank"
              >
                <div className="alllink-soc">
                  <FaFacebook />
                </div>
              </a>
              <a
                href="/"
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaTwitter />
                </div>
              </a>
              <a
                href="https://www.instagram.com/newwisdom.school/"
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaInstagram />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© All Rights Reserved by,New Wisdom School</p>
          <p className="footer-made">
            Made with <i class=" ri-heart-3-fill"></i> by{" "}
            <a href="https://maitretech.com/"> Maitretech Solution</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
